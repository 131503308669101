import React from "react";
import NavBarSoftware from "../../components/Navbar/NavbarSoftware";

const ResearchSoftware = () => {
  const dimensions = {
    width: 700,
    height: 399,
  };
  const YTFrame = {
    start: `<iframe width=${dimensions.width} height=${dimensions.height} src="`,
    end: `title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>"`,
  };
  const frame = {
    start: `<iframe srcdoc="<html><body style='margin:0;padding:0;display:flex;justify-content:center;align-items:center;height:100%;'><img src='`,
    end: `' style='width:100%;height:auto;'></body></html>" height="399" width="710" frameborder="0" allowfullscreen title="Embedded image"></iframe>`,
  };
  const datas = [
    [
      `${YTFrame.start}https://www.youtube.com/embed/wGiVSN_BC20?si=VRESAXd6Zp7nl8_U${YTFrame.end}`,
      `https://youtu.be/wGiVSN_BC20?si=7woWPmrSoilUid5T`,
    ],
    [
      `<iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7291059796579921920?compact=1" height=${dimensions.height} width=${dimensions.width} frameborder="0" allowfullscreen="" title="Embedded post"></iframe>`,
      `https://www.linkedin.com/posts/bmu-i%CC%87nha-i%CC%87kili-diplom-proqram%C4%B1_elmvaldtaldhsilnazirliyi-eduaz-daemvlaldtproqramaft-activity-7291059971545268225-5601?utm_source=share&utm_medium=member_desktop&rcm=ACoAADoouv0Batd-SijOvYMIXcQIDsdj8aFXDNA`,
    ],
    [
      `<iframe src="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:7290601651822841857?compact=1" height=${dimensions.height} width=${dimensions.width} frameborder="0" allowfullscreen="" title="Embedded post"></iframe>`,
      `https://www.linkedin.com/posts/bmu-i%CC%87nha-i%CC%87kili-diplom-proqram%C4%B1_elmvaldtaldhsilnazirliyi-eduaz-daemvlaldtproqramaft-activity-7290601778562121729-9x4R?utm_source=share&utm_medium=member_desktop&rcm=ACoAADoouv0Batd-SijOvYMIXcQIDsdj8aFXDNA`,
    ],
    [
      `${YTFrame.start}https://www.youtube.com/embed/tVrg2fIDKok?si=DbW1uGso47QBfjqk${YTFrame.end}`,
      `https://youtu.be/tVrg2fIDKok?si=Nt3Ar7wnbV6ckTG-`,
    ],
    [
      `${YTFrame.start}https://www.youtube.com/embed/51-EboVe54g?si=CNMs1cGU5EVCvZPX${YTFrame.end}`,
      `https://youtu.be/51-EboVe54g?si=pKHy4K69SEtdEUy1`,
    ],
    [
      `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Dekabr/h-a-2.jpg${frame.end}`,
      `https://www.linkedin.com/posts/bmu-i%CC%87nha-i%CC%87kili-diplom-proqram%C4%B1_elmvaldtaldhsilnazirliyi-eduaz-daemvlaldtproqramaft-activity-7244948883435356160-VZP2?utm_source=share&utm_medium=member_desktop&rcm=ACoAADoouv0Batd-SijOvYMIXcQIDsdj8aFXDNA`,
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Avqust/1-g.jpg${frame.end}`,
        `https://beu.edu.az/az/article/bmu-telebesinin-quot-genc-robotcu-quot-layihesi-2214`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Iyul/1-pp.jpg${frame.end}`,
        `https://beu.edu.az/az/article/bmu-komandasinin-hazirladigi-pua-birinci-olub-2179`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Iyun/1.jpg${frame.end}`,
        `https://beu.edu.az/az/article/bmu-da-startapci-telebelere-sertifikatlar-teqdim-edilib-2137`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/May/6-rek.jpg${frame.end}`,
        `https://beu.edu.az/az/article/rektor-beynelxalq-yarisda-qalib-gelen-bmu-komandalari-ile-gorusub-2089`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Aprel/2-robotek.jpg${frame.end}`,
        `https://beu.edu.az/az/article/bmu-komandalari-robotex-turkiye-de-birinci-yerin-qalibi-olub-2060`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Aprel/8-demo.jpg${frame.end}`,
        `https://beu.edu.az/az/article/bmu-telebelerinin-yeni-startaplari-numayis-olunub-2039`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Mart/1-hecer.jpg${frame.end}`,
        `https://beu.edu.az/az/article/bmu-inha-idp-telebeleri-ucun-kecirilmis-musabiqenin-qalibleri-mukafatlandirilib-2019`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Mart/8-humay.jpg${frame.end}`,
        `https://beu.edu.az/az/article/bmu-nun-rektoru-robotex-azerbaycan-da-ilk-yeri-tutan-komandalarin-uzvleri-ile-gorusub-1995`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Fevral/6-illik.jpg${frame.end}`,
        `https://beu.edu.az/az/article/bmu-telebelerinin-layihelerinden-ibaret-sergi-teqdimat-kecirilib-1978`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Fevral/13%20camp.jpg${frame.end}`,
        `https://beu.edu.az/az/article/bmu-da-boostcamp-7-0-layihesinin-baglanis-merasimi-kecirilib-1961`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Yanvar/3-aqrar.jpg${frame.end}`,
        `https://beu.edu.az/az/article/telebelerimiz-aqrar-innovasiya-ve-startaplar-forumunda-ferqlenibler-1928`
    ],
    [
        `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2023/Dekabr/1-elm%20sergisi.jpg${frame.end}`,
        `https://beu.edu.az/az/article/baki-muhendislik-universitetinde-qis-elm-festivali-kecirilib-1915`
    ],
    [
      `${frame.start}https://media.licdn.com/dms/image/v2/D5622AQGMExhp6n_S_Q/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1727330416184?e=1741824000&v=beta&t=6cp8lKb4M0MhGyRQMJGxWTUiWWUmjto8mqk3XHG13k8${frame.end}`,
      `https://www.linkedin.com/posts/bmu-i%CC%87nha-i%CC%87kili-diplom-proqram%C4%B1_elmvaldtaldhsilnazirliyi-eduaz-daemvlaldtproqramaft-activity-7244948883435356160-VZP2?utm_source=share&utm_medium=member_desktop&rcm=ACoAADoouv0Batd-SijOvYMIXcQIDsdj8aFXDNA`,
    ],
    [
      `${frame.start}https://media.licdn.com/dms/image/v2/D4E22AQHoJ-Y4-FAw3Q/feedshare-shrink_2048_1536/feedshare-shrink_2048_1536/0/1718272247627?e=1741824000&v=beta&t=TWyCJ9e4ENqY64jXTpwiKNp96CKRNJjgzrD7QkWMLTE${frame.end}`,
      `https://www.linkedin.com/posts/bmu-i%CC%87nha-i%CC%87kili-diplom-proqram%C4%B1_elmvaldtaldhsilnazirliyi-eduaz-daemvlaldtproqramaft-activity-7206956171457351680-pTvn?utm_source=share&utm_medium=member_desktop&rcm=ACoAADoouv0Batd-SijOvYMIXcQIDsdj8aFXDNA`,
    ],
    [
      `${frame.start}https://dp.edu.az/uploads/news/original/2024/09/4831_4_30092024150548050.jpg${frame.end}`,
      `https://dp.edu.az/az/news/4831`,
    ],
    [
      `${YTFrame.start}https://www.youtube.com/embed/Zcq8md9JiFM?si=VPCVo6HwZ_6c9R56&amp;start=47${YTFrame.end}`,
      `https://www.youtube.com/watch?v=Zcq8md9JiFM&t=6s&ab_channel=BMUTV`,
    ],
    [
      `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2024/Mart/1-tekno.jpg${frame.end}`,
      `https://beu.edu.az/en/article/bmu-nun-iki-komandasi-robotex-azerbaycan-da-birinci-yeri-tutub-1990`,
    ],
    [
      `${frame.start}https://tif.edu.az/wp-content/uploads/2024/05/12.jpg${frame.end}`,
      `https://tif.edu.az/t%C9%99hsilin-inkisafinda-innovativ-metodlar-ii-musabiq%C9%99si-qalibl%C9%99rinin-mukafatlandirilmasi-m%C9%99rasimi-kecirilib/`,
    ],
    [
      `${frame.start}https://dp.edu.az/uploads/news/original/2024/05/4762_1_15052024161556860.jpg${frame.end}`,
      `https://dp.edu.az/az/news/4762`,
    ],
    [
      `${frame.start}https://beu.edu.az/root_panel/upload/files/beu_edu_az/news/2023/1%20INN%20HHH.jpg${frame.end}`,
      `https://beu.edu.az/az/article/bmu-inha-idp-telebeleri-missiya-2030-hakatonunda-ugurlu-neticeler-gosteribler-1737`,
    ],

    [
      `<iframe width=${dimensions.width} height=${dimensions.height} src="https://www.youtube.com/embed/vlbA3tpwCAI?si=ILPA7d8vaQh8ujBX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>`,
      `https://youtu.be/vlbA3tpwCAI?si=ILPA7d8vaQh8ujBX`,
    ],
  ];

  return (
    <>
      <NavBarSoftware />
      <div
        style={{
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          paddingTop: "100px",
          paddingBottom: 0,
        }}
      >
        {datas.map((item, index) => (
          <div key={index} style={{ textAlign: "center" }}>
            <div dangerouslySetInnerHTML={{ __html: item[0] }} />
            <br />
            <button
              style={{
                marginTop: "10px",
                padding: "10px 20px",
                backgroundColor: "#0073b1",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(item[1], "_blank", "noopener,noreferrer")
              }
            >
              Details
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default ResearchSoftware;
